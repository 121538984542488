<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div v-if="article" class="pt-36 pb-56 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        {{ article.title }}
      </div>
      
      <img class="rounded h-96 mx-auto" :src="article.image" alt="Article image">

      <article class="prose lg:prose-xl mt-24 mx-auto" v-html="article.content" />
    </div>

    
    <div v-else class="flex flex-col gap-16 items-center pt-72 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4 text-xl text-center" style="min-height: 100vh;">
      We cannot find the article you are looking for.
      <button @click="$router.push('/blog')" class="py-3 px-4 flex items-center gap-4 bg-blue-900 hover:bg-blue-900 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none rounded-lg">
        Back to blog
        <img class="h-6" src="https://img.icons8.com/material-rounded/48/ffffff/right.png"/>
      </button>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

const { default: articles } = require("@/js/articles.js");

export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      article: null
    }
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  },
  created() {
    this.article = articles[this.$route.params.articleID];
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>